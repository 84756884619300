.table1-registration {
  border-collapse: collapse;
  width: 100%;
  margin: 5px 0px;
  border: 2px solid #007bff;
  border-radius: 10px;
  padding: 1rem;
  margin-bottom: 10px;
}

.text-right {
  text-align: center;
}

.text-left {
  text-align: left;
}

.card-body-registration {
  background-color: white;
  border: none;
}

.table-section-heading-registration {
  color: black;
}

.card-header-registration {
  padding: 10px;
  color: black;
  font-weight: bold;
  background-color: #F0F0F0;
}


.label-styling-registration {
  font-weight: 300;
  font-size: 16px;
}

.select-input-registration {
  text-align: left;
  font-size: 12px !important;
  font-weight: 600 !important;
  border: none !important;
  border-bottom: 1px solid grey !important;
  border-radius: 0px !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  line-height: 1.2 !important;
  transition: none !important;
}

.view-mode-stylings {
  font-size: 12px;
  font-weight: 600;
}

.text-left-registration {
  text-align: left !important;
}

.button-registration {
  margin-right: 15px;
}

.progress-bar {
  width: 50%;
  transition: width 0.5s;
  background-color: #007bff;
  color: #fff;
  padding: 10px;
  border-radius: 25px;
  text-align: center;
}

.progress-bar.completed {
  width: 100%;
}

.button-registration {
  margin-top: 0px;
}

.label-styling-registration {
  font-weight: bold;
}

.button-registration {
  margin-top: 0px;
}

.label-styling-registration {
  font-weight: bold;
}

.text-center {
  text-align: center !important;
}

.progress {
  height: 40px;
  background-color: #ccc;
  border-radius: 20px;
  margin-bottom: 20px;
  overflow: hidden;
}

.progress-bar {
  height: 100%;
  line-height: 40px;
  text-align: center;
  color: #fff;
}

.progress-bar.completed {
  background-color: #28a745;
}

.react-tel-input {
  .form-control {
    width: inherit !important;
    font-size: 12px !important;
    color: dimgray;
    margin: 0px;
    height: 36px !important;
    padding-left: 40px !important;
    border: none !important;
    border-bottom: 1px solid grey !important;
    border-radius: 3px !important;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  }

  .flag-dropdown {
    background-color: white !important;
    border: none !important;
    border-bottom: 1px solid grey !important;
    border-right: 1px solid grey !important;
    box-shadow: 0 0px 4px rgba(0, 0, 0., 0.4);
  }
}

.card-header-registration-bg {
  background-image: url('vendor5.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 20px;
  margin: 0px 20px;
  text-align: center;
}

.card-header-registration {
  background-color: rgb(230, 252, 252, 0.2);
  padding: 10px;
  border-radius: 5px;
  width: auto;
}

.card-header-registration h4 {
  margin: 0;
  color: black;
  font-size: 16px;
}

.card-header-registration-steps {
  background-color: #D3D3D3;
  padding: 10px;
  border: 0px;
}

.registration-sub-heading {
  background-color: #D8D8D8;
  width: 350px;
  text-align: left;
  padding: 10px 50px;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
}

.margin-settings {
  margin-top: 1rem !important;
  margin-left: 0rem;
}

.form-label-styling-registration {
  text-align: inherit !important;
  font-weight: 600;
  font-size: 14px;
  line-height: 1.8;
  color: #787276;
}

.check-box-styling {
  text-align: left;
  font-weight: 400;
  font-size: 12px;
}

.form-check {
  padding: 0px !important;
}

@media (max-width:500px) {
  .react-tel-input {
    .form-control {
      font-size: smaller !important;
      margin: 0px;
      height: 30px !important;
    }

    .flag-dropdown {
      height: inherit;
    }
  }

  .bank-text-content {
    font-size: 10px;
  }

  .sub-headings {
    width: 10rem !important;
    border-radius: 0.15rem 1.5rem;
    padding: 0.125rem 0.5rem 0.125rem;
  }

  .sub-headings.margin-settings {
    margin: 0px;
  }

  .sub-headings h6 {
    font-size: 0.5rem;
  }

  .bank-choose-file {
    font-size: 10px;

  }

  .margin-settings {
    margin-top: 10px !important;
    margin-left: 3rem;
  }

  .select-input-registration {
    font-size: 6px !important;
    font-weight: 400 !important;
  }

  .view-mode-stylings {
    font-size: 6px;
    font-weight: 400;
  }

  .registration-sub-heading {
    font-size: small;
    width: auto;
    padding: 10px;
  }

  .MuiStepLabel-labelContainer span {
    font-size: small;
  }

  .phone-input {
    color: red !important;
    font-size: smaller !important;
  }

  .card-header-registration-bg {
    padding: 25px;
  }

  .card-header-registration h4 {
    margin: 0;
    color: black;
    font-size: smaller;
  }

  .card-header-registration {
    padding: 10px;
  }

  .card-header-registration-steps {
    font-size: medium !important;
  }

  .card-header-registration-label {
    font-size: medium;
    padding: 0px;
    margin: 0px;
  }

  .label-styling-registration {
    font-size: small;
  }

  .form-label-styling-registration {
    font-size: 8px;
    font-weight: 600;
    color: #787276;
  }

  .check-box-styling {
    font-size: 6px;
    font-weight: 400;
  }
}

.stepper-number-click-registration {
  cursor: pointer;
}

.document-note-settings {
  display: inline-block;
  margin-bottom: 0px;
  font-weight: bold;
}

.document-note-bg {
  background-color: #f2adac;
  margin-left: 20px;
  margin-right: 10px;
  border-radius: 10px;
  padding: 5px 25px !important;
  font-weight: 300;
  text-align: left;
}

.form-control {
  padding: 0.375rem 0.75rem !important;
}

.error-message {
  color: red;
  font-size: 0.625rem;
  text-align: center;
}

.data-saved-success {
  color: green;
  font-size: 1rem;
}

.data-resetted-success {
  color: red;
  font-size: 1rem;
}

.display-inline-block {
  display: inline-block;
}

.custom-select-container {
  position: relative;
}

.custom-select-wrapper {
  position: relative;
}

.custom-select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent;
  border: none;
  outline: none;
  width: 100%;
  padding: 10px;
  cursor: pointer;
}

.custom-caret {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  pointer-events: none;
  color: #AED6F1;
}

.sub-headings h6 {
  margin: 0px;
}

.sub-headings {
  background-color: #89CFF0;
  width: 16rem;
  border-radius: 0.25rem 2.5rem;
  padding: 0.5rem 1.75rem 0.5rem;
}

.custom-select-monospace {
  font-family: monospace;
}

.spinner-container {
  height: 50px;
  width: 180px;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.loading-spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left: 4px solid #3498db;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
  display: inline-block;
}

.loading-message {
  position: fixed;
  border-radius: 5px;
  padding-left: 50px;
  font-weight: bold;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.blur {
  filter: blur(5px);
  pointer-events: none;
}